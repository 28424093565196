import React from "react"
import cx from "classnames"

import { IMG } from "../../const"
import * as styles from "../../assets/styles/outsail.module.scss"

const ApproachList = () => (
  <ul className={styles.approachList}>
    <li>
      <div className={styles.textBlock}>
        <h3>Real-time Teamwork</h3>
        <p>
          We have implemented WebSockets, enabling real-time collaboration on
          the same project. This feature is precious on the team's progress
          page, where each team member can instantly see the latest estimates
          and updates.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <div className={cx(styles.imgWrapper, styles.imgBorder)}>
          <picture>
            <source
              srcSet={`${IMG.OUTSAIL}/teamwork.avif,  ${IMG.OUTSAIL}/teamwork@2x.avif 2x, ${IMG.OUTSAIL}/teamwork@3x.avif 3x`}
              type="image/avif"
            />
            <source
              srcSet={`${IMG.OUTSAIL}/teamwork.webp,  ${IMG.OUTSAIL}/teamwork@2x.webp 2x, ${IMG.OUTSAIL}/teamwork@3x.webp 3x`}
              type="image/webp"
            />
            <source
              srcSet={`${IMG.OUTSAIL}/teamwork.jpg,   ${IMG.OUTSAIL}/teamwork@2x.jpg 2x,  ${IMG.OUTSAIL}/teamwork@3x.jpg 3x`}
            />
            <img
              src={`${IMG.OUTSAIL}/teamwork.jpg`}
              alt="Screen"
              width="570"
              height="326"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textBlock}>
        <h3>Chart</h3>
        <p>
          We have introduced a new chart functionality that allows users to add
          and subtract columns. This feature provides enhanced flexibility and
          improved data visualization options.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <div className={cx(styles.doubleImgs, styles.chartImgs)}>
          <div className={cx(styles.imgBorder, styles.img__1)}>
            <picture>
              <source
                srcSet={`${IMG.OUTSAIL}/chart-1.avif,  ${IMG.OUTSAIL}/chart-1@2x.avif 2x, ${IMG.OUTSAIL}/chart-1@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/chart-1.webp,  ${IMG.OUTSAIL}/chart-1@2x.webp 2x, ${IMG.OUTSAIL}/chart-1@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/chart-1.jpg,   ${IMG.OUTSAIL}/chart-1@2x.jpg 2x,  ${IMG.OUTSAIL}/chart-1@3x.jpg 3x`}
              />
              <img
                src={`${IMG.OUTSAIL}/chart-1.jpg`}
                alt="Screen"
                width="441"
                height="403"
                loading="lazy"
              />
            </picture>
          </div>

          <div className={cx(styles.imgBorder, styles.img__2)}>
            <picture>
              <source
                srcSet={`${IMG.OUTSAIL}/chart-2.avif,  ${IMG.OUTSAIL}/chart-2@2x.avif 2x, ${IMG.OUTSAIL}/chart-2@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/chart-2.webp,  ${IMG.OUTSAIL}/chart-2@2x.webp 2x, ${IMG.OUTSAIL}/chart-2@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/chart-2.jpg,   ${IMG.OUTSAIL}/chart-2@2x.jpg 2x,  ${IMG.OUTSAIL}/chart-2@3x.jpg 3x`}
              />
              <img
                src={`${IMG.OUTSAIL}/chart-2.jpg`}
                alt="Screen"
                width="428"
                height="243"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textBlock}>
        <h3>Convenient Team Communication</h3>
        <p>
          To facilitate effective team communication, we have added a team chat
          window equipped with features such as likes and repins. Additionally,
          we have incorporated the ability to modify the weight of a character
          during vendor calls, ensuring that the entire team stays informed
          about any changes made.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <div className={cx(styles.doubleImgs, styles.communicationImgs)}>
          <div className={cx(styles.imgBorder, styles.img__1)}>
            <picture>
              <source
                srcSet={`${IMG.OUTSAIL}/communication-1.avif,  ${IMG.OUTSAIL}/communication-1@2x.avif 2x, ${IMG.OUTSAIL}/communication-1@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/communication-1.webp,  ${IMG.OUTSAIL}/communication-1@2x.webp 2x, ${IMG.OUTSAIL}/communication-1@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/communication-1.jpg,   ${IMG.OUTSAIL}/communication-1@2x.jpg 2x,  ${IMG.OUTSAIL}/communication-1@3x.jpg 3x`}
              />
              <img
                src={`${IMG.OUTSAIL}/communication-1.jpg`}
                alt="Screen"
                width="370"
                height="201"
                loading="lazy"
              />
            </picture>
          </div>
          <div className={cx(styles.imgBorder, styles.img__2)}>
            <picture>
              <source
                srcSet={`${IMG.OUTSAIL}/communication-2.avif,  ${IMG.OUTSAIL}/communication-2@2x.avif 2x, ${IMG.OUTSAIL}/communication-2@3x.avif 3x`}
                type="image/avif"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/communication-2.webp,  ${IMG.OUTSAIL}/communication-2@2x.webp 2x, ${IMG.OUTSAIL}/communication-2@3x.webp 3x`}
                type="image/webp"
              />
              <source
                srcSet={`${IMG.OUTSAIL}/communication-2.jpg,   ${IMG.OUTSAIL}/communication-2@2x.jpg 2x,  ${IMG.OUTSAIL}/communication-2@3x.jpg 3x`}
              />
              <img
                src={`${IMG.OUTSAIL}/communication-2.jpg`}
                alt="Screen"
                width="226"
                height="176"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div className={styles.textBlock}>
        <h3>Autosave</h3>
        <p>
          Our platform includes an autosave feature that alerts users when there
          is no internet access. This ensures that team members cannot edit
          areas with hints on different devices, preserving the integrity of the
          data and preventing conflicts.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <div className={styles.autosaveBlock}>
          <img
            className={styles.autosaveImg}
            src={`${IMG.OUTSAIL}/autosave.svg`}
            alt="Cloud"
            loading="lazy"
            width="167"
            height="131"
          />
          <div>
            <div>Design Demo has been Submitted</div>
          </div>
          <div>
            <div>Design demo data is saved!</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
)

export default ApproachList
