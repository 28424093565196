import React from "react"

import { IMG } from "../../const"

const PictureMvp = () => (
  <picture>
    <source
      srcSet={`${IMG.OUTSAIL}/mvp.avif,  ${IMG.OUTSAIL}/mvp@2x.avif 2x, ${IMG.OUTSAIL}/mvp@3x.avif 3x`}
      type="image/avif"
    />
    <source
      srcSet={`${IMG.OUTSAIL}/mvp.webp,  ${IMG.OUTSAIL}/mvp@2x.webp 2x, ${IMG.OUTSAIL}/mvp@3x.webp 3x`}
      type="image/webp"
    />
    <source
      srcSet={`${IMG.OUTSAIL}/mvp.jpg,   ${IMG.OUTSAIL}/mvp@2x.jpg 2x,  ${IMG.OUTSAIL}/mvp@3x.jpg 3x`}
    />
    <img
      src={`${IMG.OUTSAIL}/mvp.jpg`}
      alt="Screen MVP"
      width="540"
      height="599"
      loading="lazy"
    />
  </picture>
)

export default PictureMvp
