// extracted by mini-css-extract-plugin
export var approachList = "outsail-module--approachList--e525e";
export var autosaveBlock = "outsail-module--autosaveBlock--4809b";
export var autosaveImg = "outsail-module--autosaveImg--96e90";
export var bgCircles = "outsail-module--bg-circles--5fc07";
export var billetShadow = "outsail-module--billet-shadow--4a6c4";
export var challengeList = "outsail-module--challengeList--a47b2";
export var chartImgs = "outsail-module--chartImgs--5cda4";
export var colorScheme__background__grayPale = "outsail-module--colorScheme__background__gray-pale--427fb";
export var communicationImgs = "outsail-module--communicationImgs--27152";
export var contactForm = "outsail-module--contactForm--a4cc5";
export var desktopImgWrapper = "outsail-module--desktopImgWrapper--1f2ba";
export var details = "outsail-module--details--9148e";
export var doubleImgs = "outsail-module--doubleImgs--bb1c2";
export var feedback = "outsail-module--feedback--819fb";
export var heading = "outsail-module--heading--1c77d";
export var icomoon = "outsail-module--icomoon--ffd53";
export var imgBlock = "outsail-module--imgBlock--a919d";
export var imgBorder = "outsail-module--imgBorder--6a0f1";
export var imgWrapper = "outsail-module--imgWrapper--23d07";
export var img__1 = "outsail-module--img__1--cc863";
export var img__2 = "outsail-module--img__2--b719a";
export var jobTitle = "outsail-module--jobTitle--7fe82";
export var linkWrapper = "outsail-module--linkWrapper--3c12e";
export var listsBlock = "outsail-module--listsBlock--4eff6";
export var logoWrapper = "outsail-module--logoWrapper--6123d";
export var lowercase = "outsail-module--lowercase--59ad8";
export var mainImg = "outsail-module--mainImg--644db";
export var mainList = "outsail-module--mainList--a154b";
export var mainText = "outsail-module--mainText--12f26";
export var name = "outsail-module--name--15799";
export var outsailBlock = "outsail-module--outsailBlock--6753d";
export var shine = "outsail-module--shine--0cb86";
export var subtext = "outsail-module--subtext--22092";
export var technologiesBlock = "outsail-module--technologiesBlock--0fbb6";
export var textBlock = "outsail-module--textBlock--ea687";
export var toOutsail = "outsail-module--toOutsail--8eccf";
export var transition = "outsail-module--transition--43f36";
export var transitionBackground = "outsail-module--transition-background--53227";
export var transitionReverse = "outsail-module--transition-reverse--118ee";
export var uppercase = "outsail-module--uppercase--da80e";