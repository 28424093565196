import React from "react"
import ChallengeList from "../components/outsail/ChallengeList"
import ApproachList from "../components/outsail/ApproachList"
import PictureMvp from "../components/outsail/PictureMvp"

export const TECHNOLOGIES = [
  { name: "React", icon: "react-native.svg" },
  { name: "Ruby", icon: "ruby.svg" },
  { name: "AWS", icon: "aws.svg" },
  { name: "Figma", icon: "icon-figma.svg" },
  { name: "Miro", icon: "miro-icon.svg" },
]

export const OPTIONS = [
  {
    id: 1,
    title: "Client",
    text:
      "OutSail is a web platform dedicated to helping businesses find the right HR software solution to address their specific challenges.",
  },
  {
    id: 2,
    title: "Discovery and Design",
    text:
      "Our natural first step was discovering the existing solutions and preparing system design and architecture. We went through a series of workshops with clients that included Scoping and Descoping, Prioritization, Estimation, and Planning workshops. This took us 12 working days, but as a result, the client had a very clear vision of what our team was offering to build, how long it would take, and how much it would cost to implement the system.",
  },
  {
    id: 3,
    title: "Challenge 1",
    text:
      "Our main objective was to develop a minimum viable product (MVP) that kick-starts the digitalization process for agencies, and to see the reaction of real users.",
  },
  {
    id: 4,
    title: "MVP",
    text:
      "Our MVP focused on assisting users in finding the ideal HR tool to address their specific tasks. We achieved this by creating a user-friendly web platform that simplifies onboarding. Users are guided to select essential categories relevant to their tool requirements and answer questions based on their responses. The platform then identifies the best HR tool that matches their needs.",
    img: <PictureMvp />,
  },
  {
    id: 5,
    title: "Challenge 2",
    text: (
      <strong>
        Our next challenge was scaling the agency's business model. We
        accomplished this by leveraging a digital product to expedite
        decision-making and purchasing through the digitalization of business
        processes.
      </strong>
    ),
    list: <ChallengeList />,
  },
  {
    id: 6,
    title: "Incremental Development Approach",
    text:
      " To ensure a seamless user experience and frequent updates to the platform, we have divided the necessary functionality into incremental releases. This approach allows us to deliver new features step-by-step, ensuring users don't have to wait months to benefit from enhancements.",
    list: <ApproachList />,
  },
  {
    id: 7,
    title: "Outcomes",
    text: (
      <strong>
        We have been consistently enhancing OutSail by introducing new features
        through regular releases. Our clients have been progressively
        incorporating new functionalities into their business model. This
        digital solution, at the MVP stage, has reduced users'&nbsp;time
        searching for the best HR vendor by <mark>52%</mark>.
      </strong>
    ),
    subtext: (
      <>
        Our product has already yielded significant cost savings, amounting to
        an impressive <mark>$450,000</mark> of annual fixed expenses.
      </>
    ),
  },
]
