import React from "react"

import { IMG } from "../../const"
import * as styles from "../../assets/styles/outsail.module.scss"

const ChallengeList = () => (
  <ul className={styles.challengeList}>
    <li>
      <div className={styles.textBlock}>
        <h3>Audit</h3>
        <p>
          Our initial goal was to audit the minimum viable product (MVP), which
          helped us identify the necessary changes to ensure the product's
          scalability.
        </p>
        <p>
          Our primary goal was to scale the MVP and expand the platform's
          functionality. Through the MVP audit, we were able to understand the
          key areas of platform development needed to achieve our goals.
        </p>
        <p>
          The analysis revealed blocking factors that make it impossible to
          scale the business model. Our goal is to make the platform more
          autonomous and self-sufficient.
        </p>
        <p>
          The first market testing of the MVP allowed us to determine the level
          of user conversion. The analysis helped us identify gaps and
          opportunities to improve the user experience and the platform
          interface.
        </p>
        <p>
          After conducting the audit, we pinpointed specific obstacles hindering
          the platform's scalability and impeding a shorter user purchase
          decision path. Consequently, we concluded that in order to achieve
          scalability and streamline user flows, it is crucial to enhance and
          develop a comprehensive next version of the product.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${IMG.OUTSAIL}/audit-schema-mobile.svg`}
          />
          <source
            media="(min-width: 769px)"
            srcSet={`${IMG.OUTSAIL}/audit-schema.svg`}
          />
          <img
            src={`${IMG.OUTSAIL}/audit-schema.svg`}
            alt="Schema Audit"
            width="526"
            height="468"
            loading="lazy"
          />
        </picture>
      </div>
    </li>

    <li>
      <div className={styles.textBlock}>
        <h3>Business Analysis</h3>
        <p>
          We updated the product vision and company vision, which improved the
          understanding of our direction, what we wanted to achieve, and the
          desired value that the product should deliver.
        </p>
        <p>
          After that, we created a step-by-step development plan to define the
          steps to achieve the goal and set success criteria. We make new
          critical processes and conduct additional research, such as competitor
          analysis and user and product research. This work helps us find new
          insights that we test regularly. In addition to this, we develop a
          customer lifecycle plan and set short and long-term key performance
          indicators (KPIs) that will serve as benchmarks to measure our
          progress.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${IMG.OUTSAIL}/business-analysis-schema-mobile.svg`}
          />
          <source
            media="(min-width: 769px)"
            srcSet={`${IMG.OUTSAIL}/business-analysis-schema.svg`}
          />
          <img
            src={`${IMG.OUTSAIL}/business-analysis-schema.svg`}
            alt="Schema"
            width="489"
            height="492"
            loading="lazy"
          />
        </picture>
      </div>
    </li>

    <li>
      <div className={styles.textBlock}>
        <h3>Product Design</h3>
        <p>
          At the implementation stage of the step-by-step roadmap, we started
          working on product design, dividing it into several phases:
          identification, creativity, and innovation.
        </p>
        <p>
          The ideation phase focused on researching and developing artifacts
          like information architecture. At the end of this phase, we had
          ready-made artifacts to create our look and feel, i.e., the product's
          appearance. We implemented the validated features and capabilities we
          gained from the research.
        </p>
        <p>
          Subsequently, we conducted A/B testing of the platform's features on
          real users to further enhance its convenience and user-friendliness.
        </p>
        <p>
          We also developed user journey mapping, which was a breakthrough
          moment where our product transformed entirely. This tool helped us
          finalize the design and change the business model as a whole.
        </p>
        <p>
          As a result, we developed a visual component and improved the user
          experience, which was included in the new version of the product. The
          central importance of product design is that the latest version of the
          product not only scales the business model but also expands the
          functionality to meet users' needs.
        </p>
      </div>
      <div className={styles.imgBlock}>
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${IMG.OUTSAIL}/product-design-schema-mobile.svg`}
          />
          <source
            media="(min-width: 769px)"
            srcSet={`${IMG.OUTSAIL}/product-design-schema.svg`}
          />
          <img
            src={`${IMG.OUTSAIL}/product-design-schema.svg`}
            alt="Schema"
            width="469"
            height="600"
            loading="lazy"
          />
        </picture>
      </div>
    </li>
  </ul>
)

export default ChallengeList
